export function startRequest() {
  return {
    type: '@loading/START_REQUEST'
  }
}

export function finishRequest() {
  return {
    type: '@loading/FINISH_REQUEST'
  }
}